.followListWrap .ant-modal-content,
.followListWrap .ant-modal-body,
.followListWrap .ant-modal-header{
  border-radius: 7px;
}

.followListWrap .ant-modal-title{
  font-size: 20px;
  line-height: 28px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
}